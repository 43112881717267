<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("managecustomer") }}
      </h3>
      <div class="card-toolbar">
        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="ข้อมูลสินค้า.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
        <button
          v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <label class="form-label col-sm-10">Code/Name</label>
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
          @keypress.enter="search()"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <label for="validationTooltip01" class="form-label col-sm-10">{{
          $t("branchs")
        }}</label>
        <select
          :style="
            isSubmit
              ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
              : ''
          "
          class="form-select"
          v-model="form.branchId"
        >
          <div class="invalid-feedback text-end">กรุณากรอก</div>
          <option v-for="item in branches" :key="item" :value="item.id">
            {{ item.code }} {{ item.code ? "-" : "" }} {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-sm-3 mb-3">
        <label for="validationTooltip01" class="form-label col-sm-10">{{
          $t("business_type")
        }}</label>
        <select
          :style="
            isSubmit
              ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
              : ''
          "
          class="form-select"
          v-model="form.business_type"
        >
          <div class="invalid-feedback text-end">กรุณากรอก</div>
          <option value="">ทั้งหมด</option>
          <option value="Cash Van">Cash Van</option>
          <option value="Pre order">Pre order</option>
        </select>
      </div>
      <div class="col-sm-3 mb-3">
        <label for="validationTooltip01" class="form-label col-sm-10">{{
          $t("route")
        }}</label>
        <select
          :style="
            isSubmit
              ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
              : ''
          "
          class="form-select"
          v-model="form.routeId"
        >
          <div class="invalid-feedback text-end">กรุณากรอก</div>
          <option v-for="item in routes" :key="item" :value="item.id">
            {{ item.code }} {{ item.code ? "-" : "" }} {{ item.name }}
          </option>
        </select>
      </div>
      <!-- <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div> -->
      <div class="col-sm-3 mt-4 text-start" style="align-self: center">
        <button
          @click="clear()"
          type="button"
          class="btn btn-sm btn-light-danger me-2"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("clear") }}
        </button>
        <!-- </div>
      <div class="col-sm-1 mt-4 text-end" style="align-self: center"> -->
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>

    <div class="card-body pt-3">
      <!-- searchbar -->
      <!-- <Search @textSearch="textSearch" /> -->
      <!-- searchbar -->

      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'code'">
              {{ $t("customercode") }}
            </template>
            <template v-if="column.dataIndex === 'name'">
              {{ $t("customername") }}
            </template>
            <template v-if="column.dataIndex === 'address'">
              {{ $t("address") }}
            </template>
            <template v-if="column.dataIndex === 'branch.name'">
              {{ $t("branchs") }}
            </template>
            <template v-if="column.dataIndex === 'business_type'">
              {{ $t("businesstype") }}
            </template>
            <template v-if="column.dataIndex === 'route'">
              {{ $t("route") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'branch.name'">
              <span v-if="record.branch">
                {{ record.branch ? record.branch.name : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'route'">
              <span v-if="record.route">
                {{ record.route ? record.route.code : "" }} |
                {{ record.route ? record.route.name : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 'ขายปกติ'" style="color: green">{{
                record.status
              }}</span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div
                  class="col-sm-6 d-flex justify-content-md-end"
                  v-if="userAuth.isCanModified"
                >
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>#</th>
              <th>{{ $t("customercode") }}</th>
              <th>{{ $t("customername") }}</th>
              <th>{{ $t("address") }}</th>
              <th>{{ $t("branchs") }}</th>
              <th>{{ $t("business_type") }}</th>
              <th>{{ $t("route") }}</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>
                {{ item.code ? item.code : "-" }}
              </td>
              <td>
                {{ item.name ? item.name : "-" }}
              </td>
              <td>
                {{ item.address ? item.address : "-" }}
              </td>

              <td>
                {{ item.branch ? item.branch.name : "_" }}
              </td>
              <td>
                {{ item.business_type ? item.business_type : "-" }}
              </td>
              <td>
                <span v-if="item.route">
                  {{ item.route.code ? item.route.code : "" }} |
                  {{ item.route.name ? item.route.name : "" }}
                </span>
              </td>

              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import customerAPI from "@/api/customer/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";
import userApi from "@/api/user/";
import { Decode } from "@/services";

export default {
  setup() {
    document.title = "CHOMTHANA | เพิ่มข้อมูลลูกค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
//     26. ในเมนู จัดการลูกค้า ปุ่ม download excel 
// เวลาดาวน์โหลดให้เพิ่มฟิลด์ ประเภทร้านค้า- Pricelist- ส่วนลดท้ายบิล-รหัสตู้ มาด้วยครับ
    json_fields: {
      customercode: "code",
      customername: "name",
      address: "productGroup",
      branchs: {
        field: "branch",
        callback: (value) => {
          return `${value.name ? value.name : ""}`;
        },
      },
      business_type: "business_type",
      
      route: {
        field: "route",
        callback: (value) => {
          return `${value.code ? value.code : ""} ${
            value.name ? value.name : ""
          }`;
        },
      },
      storeType: {
        field: "storeType",
        callback: (value) => {
          return `${value.code ? value.code : ""} ${
            value.name ? value.name : ""
          }`;
        },
      },
      priceListId: "priceListId",
      'ส่วนลดท้ายบิล': "discount",
      'รหัสตู้': "discount",
    },
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "customercode", dataIndex: "code" },
      { title: "customername", dataIndex: "name" },
      { title: "address", dataIndex: "address" },
      { title: "branchs", dataIndex: "branch.name" },
      { title: "business_type", dataIndex: "business_type" },
      { title: "route", dataIndex: "route" },
      { title: "", dataIndex: "action" },
    ],

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
    form: {
      storeTypeId: "",
      branchId: "",
      routeId: "",
      business_type: "",
    },
    branches: [],
    store_types: [],
    routes: [],
    userAuth: [],
    userLocal: [],
    masterMenuId: 12,
  }),

created() {
    this.checkMenu();
    this.getAll();
    this.getAllBranch();
    this.getAllStoreType();
    this.getAllRoute();
  },

  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAllBranch() {
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;
        this.branches.unshift({ name: "ทั้งหมด", id: "" });
      }
    },
    async getAllStoreType() {
      let responseData = [];
      try {
        responseData = await customerAPI.store_type.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.store_types = responseData.data;
        this.store_types.unshift({ name: "ทั้งหมด", id: "" });
      }
    },
    async getAllRoute() {
      let responseData = [];
      try {
        responseData = await customerAPI.route.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.routes = responseData.data;
        this.routes.unshift({ name: "ทั้งหมด", id: "" });
      }
    },

    async getAll() {
      this.loading = true;
      let branchId = localStorage.getItem("branchId");
      let companyId = localStorage.getItem("companyId");
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(branchId, companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/CUSTOMER/new",
        query: {},
      });
    },
    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "CUSTOMER-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "CUSTOMER-edit",
      //   query: {
      //     id: id,
      //   },
      // });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.name} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await customerAPI.customer.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await customerAPI.customer.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },
    clear() {
      this.form = {
        storeTypeId: "",
        routeId: "",
        branchId: "",
      };
      this.getAll();
    },
    async search() {
      this.loading = true;
      // storeTypeId: this.form.storeTypeId,
      // branchId: this.form.branchId,
      // routeId: this.form.routeId,

      let body = {
        search: this.searchInput,
        business_type:this.form.business_type,
        companyId: localStorage.getItem("companyId"),
        branchId: this.form.branchId,
      };
      let query = "";

      // if (this.form.storeTypeId) {
      //   let queryStoreType = `?storeTypeId=${this.form.storeTypeId}`;
      //   if (this.form.branchId) {
      //     queryStoreType += `&branchId=` + this.form.branchId;
      //   }
      //   if (this.form.routeId) {
      //     queryStoreType += `&routeId=` + this.form.routeId;
      //   }
      //   query = queryStoreType;
      // } else if (this.form.branchId) {
      //   let queryBranch = `?branchId=${this.form.branchId}`;
      //   if (this.form.storeTypeId) {
      //     queryBranch += `&storeTypeId=` + this.form.storeTypeId;
      //   }
      //   if (this.form.routeId) {
      //     queryBranch += `&routeId=` + this.form.routeId;
      //   }
      //   query = queryBranch;
      // } else if (this.form.RouteId) {
      //   let queryRoute = `?RouteId=${this.form.RouteId}`;
      //   if (this.form.storeTypeId) {
      //     queryRoute += `&storeTypeId=` + this.form.storeTypeId;
      //   }
      //   if (this.form.branchId) {
      //     queryRoute += `&branchId=` + this.form.branchId;
      //   }
      //   query = queryRoute;
      // }

      // const responseSearch = await customerAPI.customer.filterdata(query);
      // const responseSearch = await customerAPI.customer.search(body, query);
      const responseSearch = await customerAPI.customer.searchpos(body);
      console.log("query", query);
      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;

        this.loading = false;
      }
    },
  },
};
</script>
